<template>
<v-card>
  <v-row no-gutters class="mx-2">
    <v-col cols="12" v-if="isLoaded" class="mt-6">
      <v-row no-gutters>

        <template
          v-for="(compositionItem, index) of composition"
        >
          <v-col cols="12" md="3" :key="compositionItem.id">
            <v-card>
            <v-row justify="center" align="center">
              <v-col cols="12" class="text-right">
                <BtnIconDelete
                  @click="deleteMaterial(index)"
                />
              </v-col>
              <v-col cols="12" class="text-center">
                <ProgressPercentage
                  :value="compositionItem.percentage*100"
                />
              </v-col>
              <v-col cols="12" class="text-center">
                <router-link v-if="compositionItem.material" :to="{ name: 'Material', params: { id: compositionItem.material.id } }">
                  {{ compositionItem.material.name }}
                </router-link>
                <span v-else>{{ $t('common.misc.NA') }}</span>
              </v-col>
              <v-col cols="12" v-if="compositionItem.material" class="text-center">
                {{ compositionItem.material.raw_material }}
              </v-col>
            </v-row>
            </v-card>
          </v-col>
        </template>

        <!-- Hover card -->
        <v-hover>
          <v-col
            cols="12"
            lg="4"
            :class="`bg-main pointer ma-1 elevation-${hover ? 12 : 2}`"
            slot-scope="{hover}">
            <v-row
              align="center"
              justify="center"
              @click="toggleAddOrEditDialog(true)"
              style="min-height: 100px;">
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-icon x-large class="text-center mt-2 d-block" flat>fas fa-plus-circle</v-icon>
                  </v-col>
                  <v-col cols="12" class="mt-1">
                    <p class="text-center">{{$t('common.buttons.add')}}</p>
                  </v-col>
                </v-row>
              </v-col>

            </v-row>
          </v-col>
        </v-hover>
      </v-row>
    </v-col>
    <v-col cols="12" v-else>
      <Skeleton :occurrence="1" type="list" />
    </v-col>
  </v-row>

  <!-- Dialog -->
  <ComponentCompositionDialog
    v-if="showDialog.addOrEdit"
    :value="showDialog.addOrEdit"
    @close="toggleAddOrEditDialog(false)"
    @submit="(newComposition) => addMaterials(newComposition)"
  />
</v-card>
</template>

<script>

export default {
  name: "ComponentCompositionTab",

  components: {
    Skeleton: () => import("@/components/Common/Cards/Skeleton"),
    ProgressPercentage: () => import( "@/components/Common/ProgressPercentage"),
    BtnIconDelete: () => import("@/components/Common/Buttons/BtnIconDelete"),
    ComponentCompositionDialog: () => import("@/components/Components/ComponentCompositionDialog")
  },

  props: {
    component: { type: Object, default: null },
  },

  data() {
    return {
      showDialog: {
        addOrEdit: false
      },
      currentItem: null,
      isLoaded: false,
      composition: []
    }
  },

  created() {
    this.getData();
  },

  methods: {
    getData() {
      this.isLoaded = false;
      this.$http.get(`/components/${this.component.id}/composition`, {
        headers: {Authorization: "Bearer " + this.$session.get('jwt')}
      })
        .then(res => {
          this.composition = res.data;
        })
        .catch(err => {
          this.$store.commit("alert/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoaded = true;
        });
    },

    toggleAddOrEditDialog(val, item = null) {
      this.currentItem = item;
      this.showDialog.addOrEdit = val;
    },

    addMaterials(items) {
      this.composition.push(...items);
      this.toggleAddOrEditDialog(false);
      this.updateComposition();
    },

    deleteMaterial(index) {
      this.composition.splice(index, 1);
      this.updateComposition();
    },

    updateComposition() {
      for (const el of this.composition) {
        if (el.material["id"])
          el.material = el.material.id
      }

      this.$http
        .put(`/components/${this.component.id}/composition`, { composition: this.composition }, {
          headers: { Authorization: "Bearer " + this.$session.get('jwt') }
        })
        .then(res => {
          this.getData();
        })
        .catch(err => {
          this.$store.commit("toast/showErrorAxios", err);
        })
        .finally(() => {
        })
    }
  }
};
</script>

<style scoped>

</style>
